.slider-swiper {
    margin-bottom: 20px;
  }
  
  .slider-swiperbx {
    text-align: center;
  }
  
  .progress-bar-container {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    position: relative;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .progress-bar {
    height: 100%;
    transition: width 0.3s ease;
  }

  
  .swiper-pagination {
    bottom: auto !important; /* Remove or override the bottom property */
    top: 100%; /* Optional: Place pagination at the top if needed */
  }
  