body {
    background: #EEF4FF;
    margin: 0;
    font-family: sans-serif;
    color: #333;
  }
  
  .spacer {
    width: 100%;
    height: 50vh;
    background: #ddd;
  }
  
  .gallery h4 {
    font-size: clamp(2em, 4vw, 6em);
  }
  
  .gallery p {
    font-size: clamp(1.4em, 2.5vw, 3.5em);
    line-height: 1.4;
  }
  
  .gallery {
    display: flex;
  }
  
  .left {
    width: 100%;
  }
  
  .right {
    height: 100vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
  }
  
  .desktopContent {
    margin: auto;
    width: 80%;
  }
  
  .desktopContentSection {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .desktopPhotos {
    width: 40vw;
    height: 40vw;
    position: relative;
    /* overflow: hidden; */
  }
  
  .desktopPhoto {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
  }

  /* .testing{
    animation: fadeIn 5s;
  } */

  /* .desktopPhoto img{animation: customAni 2s ease 0s 1 normal none;}  */
  @keyframes customAni {
    0% {
    transform: scale(0.5);
    }
  
    100% {
    transform: scale(1);
    }
  }

  .desktopPhoto img{animation: fadeIn 5s;}
  /* .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  } */
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  /* .red {
    background:crimson;
  } */
  
  .desktopPhoto.green {
    background: #fff;
  }
  
  .desktopPhoto.blue {
    background: #fff;
  }
  
  .desktopPhoto.pink {
    background: #fff;
  }
  
  .gallery .col-md-7 img{float: right;}
  .mobileContent {
    display: none;
    width: 80vw;
  }
  
  .mobilePhoto {
    width: 80vw;
    height: 80vw;
    margin-top: 5em;
    border-radius: 6vw;
  }
  
 
  @media screen and (max-width: 599px) {
    .left {
      display: block;
    }
  
    .right {
      height: auto;
      width: 100%;
      align-items: center;	
    }	
  
    .desktopPhotos {
      display: none;
    }
  
    .mobileContent {
      display: block;
    }
  }
  

  
  .desktopPhoto {
    opacity: 1;
    transition: opacity 4s ease; 
  }
  
  /* .desktopPhoto img{animation: fadeIn 5s;} */

  /* .scroll-section {
    opacity: 1;
    transition: opacity 3s ease;
  }
  
  .scroll-section.fade-in {
    opacity: 1;
    transition: opacity 3s ease;
  }
   */
 
  